import React, { Component } from 'react';
import Header from '../component/layout/header';
import FooterSection from '../component/layout/footer';
import BannerSection from '../component/section/banner'; 
import MemberSection from '../component/section/member';
import AboutSection from '../component/section/about';
import WorkSection from '../component/section/work';
import MemberTwoSection from '../component/section/memberTwo';
import AppsSection from '../component/section/apps';

class HomePages extends Component {
    render() {
        return (
            <div>
                <Header />
                <BannerSection />
                <MemberSection />
                <AboutSection />
                <WorkSection />
                <MemberTwoSection />
                {/* <AppsSection /> */}
                {/* <FooterSection /> */}
            </div>
        );
    }
}

export default HomePages;