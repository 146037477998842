import React,{useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signupUser } from '../../store/users.store';
import Header from '../../component/layout/header';
import PageHeader from '../../component/layout/pageheader';
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { ToastContainer } from "react-toastify"
import Loader from '../../utils/Loader';
import { upload } from '../../store/users.store';


const title = "Register Now";
const SignInPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.users.loading);
    const [signUpDetails,setSignUpDetails] = useState({});
    const [activeForm,SetActiveForm] = useState(false);

    const [file, setFile] = useState(null);

    const user_images = useSelector((state) => state?.users?.upload?.uploaded_files);

    const handleFormSubmit = (values, { setSubmitting }) => {
        values['user_images'] = [];
        setSignUpDetails(values);

        //display the image form
        SetActiveForm(true);

        // console.log(values);
        // dispatch(signupUser(values)).then((result) => {
        //     if (result?.payload?.message === "success") {
        //         navigate("/auth")
        //     }
        // })
        // setSubmitting(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        //When clicked, 

        if (!file) {
            console.error("No file selected");
            return;
        }
        const formData = new FormData();
        formData.append("file", file); 

        dispatch(upload(formData)).then((result) => {
            if (result?.payload?.successful === 1) {
                console.log("The result is", result);
                // navigate("/signup")
                signUpDetails['user_images'] = user_images;
                setSignUpDetails(signUpDetails['user_images'] = user_images);

                dispatch(signupUser(signUpDetails)).then((result) => {
                if (result?.payload?.message === "success") {
                        navigate("/auth")
                    }else{
                        //Display the signup form
                        SetActiveForm(false)
                    }
                })
            }
        });
    };

    return (
        <div>
            <Header />
            <PageHeader title={'Sign Up'} />
            <div className={`login-section padding-tb ${activeForm ? 'd-none': ''}`}>
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <Formik
                                initialValues={{
                                    email: "",
                                    first_name: "",
                                    surname: "",
                                    password: "",
                                    password_confirm: "",
                                    phone_number: "",
                                    referall_code: ""
                                  }}
                    
                                  onSubmit={handleFormSubmit}   
                        >
                            <Form className="account-form">
                                <div className="form-group">
                                    <Field
                                        type="email"
                                        name="email"
                                        id="item01"
                                        placeholder="Your email *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="first_name"
                                        id="item02"
                                        placeholder="First Name *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="surname"
                                        id="item03"
                                        placeholder="Surname *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="password"
                                        name="password"
                                        id="item04"
                                        placeholder="Password *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="password"
                                        name="password_confirm"
                                        id="item05"
                                        placeholder="Password Confirm *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="phone_number"
                                        id="item06"
                                        placeholder="Phone number *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="referall_code"
                                        id="item07"
                                        placeholder="Referral Code"
                                    />
                                </div>
                                <div className="form-group">
                                    <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>

                                    <button className="d-block lab-btn" disabled={loading}><span>Next</span></button>
                                </div>
                            </Form>
                        </Formik>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>       
                        </div>
                    </div>
                </div>
            </div>


            
            <div className={`login-section ${activeForm ? '':'d-none'}` }>
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Transform pixels into memories: upload your image </h3>
                        <form onSubmit={handleSubmit} className="upload-form">
                            <div className="form-group">
                            <label htmlFor="file" className="file-label">
                                <span className="file-icon h1 mx-4">&#x1F4F7;</span>
                                {file && (
                                <div className="shadow row w-100 ">
                                    <div className='p-3'>
                                        <img src={URL.createObjectURL(file)} alt="Selected" className="w-100selected-preview" />
                                    </div>
                                </div>
                                )}

                                <input type="file" id="file"
                                    name="file"
                                    multiple
                                    className="mt-4"
                                    onChange={(e)=>setFile(e.target.files[0])}
                                />
                            </label>
                            </div>


                            <button type="submit" 
                            disabled={loading}
                            className="d-block btn-outline-success btn w-100  mt-4 px-1 py-2">
                                <strong className=''>{file ? "Next" : "Upload"}</strong>
                                <span className={`${!file ? 'd-none':''} file-icon h2`}>&#x2192;</span>

                            </button>
                        </form>
                    </div>
                </div>
            </div>
            
            <ToastContainer />
        </div>
    );
};

export default SignInPage;
