import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const navigate = useNavigate;

const PageHeader =(props)=> {

    const {title} = props;
    return (
        <section className="page-header-section sticky"  style={{ backgroundImage: "url(/assets/images/page-header.jpg)", position: "sticky", top: 0,zIndex:"1000" }}>
            <div className="container">
                <div className="page-header-content">
                    <div className="page-header-inner">
                        <div className="page-title">

                        <ol className="breadcrumb p-2">
                            <li className='mx-2' onClick={()=>navigate("/")}><Link to="/">Home</Link></li> |  
                            <li className='mx-2'><NavLink to="/members">Members</NavLink></li> | 
                            <li className='mx-2'><NavLink to="/events">Events</NavLink></li> | 
                            <li className='mx-2'><NavLink to="/packages">Packages</NavLink></li> |
                            <li className='mx-2'><NavLink to="/invoices">Invoices</NavLink></li> 

                        </ol>


                            <h3>MatchKenya {title}</h3>
                        </div>
                        <ol className="breadcrumb mb-5">
                            <li  className='mx-2'><Link to="/">Home</Link></li> | 
                            <li className="active mx-2">{title}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    );
    
}

export default PageHeader;