import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { users, accountDetails } from '../store/users.store';
import PageHeader from '../component/layout/pageheader';
import Deck from './Deck';
import Loader from '../utils/Loader';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import BottomBar from '../component/layout/BottomBar';

const Members = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const user = useSelector((state) => state?.users?.accountDetails);
    const [key, setKey] = useState('matches');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(screenWidth <= 768);
    const [small,setSmall] = useState(false)



    useEffect(() => {
        dispatch(users()).then((result) => {
            if (result.payload.results.length > 1) {
                setLoaded(true);
            }
        });

        dispatch(accountDetails());
    }, []);

    useEffect(() => {
        // Function to update screenWidth and isMobile
        const updateScreenWidth = () => {
          const newWidth = window.innerWidth;
          setScreenWidth(newWidth);
          setIsMobile(newWidth <= 768);
        };

        if(isMobile){
            setSmall(true)
            console.log("It is mobile")
        }else if(!isMobile){
            setSmall(false)
        }
    
        // Attach event listener for window resize
        window.addEventListener('resize', updateScreenWidth);
    
        // Call the update function once to get the initial screen width and set isMobile
        updateScreenWidth();
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', updateScreenWidth);
        };
      }, [screenWidth]);

    return (
        <div className='row'>
            <div className='col-2 account-members-offcanvas'>

            <div class="offcanvas offcanvas-start show account-members" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                    <div class="offcanvas-header-member  mx-1 row ">
                        
                        <div className="account-member-img col-4">
                                <img src="https://itstrevor.vercel.app/img/1.jpg" alt="user image" />
                        </div>

                        <div  className="account-details text-center col-8 py-4">
                            <h6 className="user-name text-start">{user?.full_name}</h6>                
                        </div>
                        
                    </div>
                
                    <div className="offcanvas-body">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="matches" title="Matches" className='tab-btn'>
                                <hr/>
                                <p>Matches content goes here...</p>
                            </Tab>
                            <Tab eventKey="likes" title="Likes" className='tab-btn'>
                                <hr/>
                                <p>Likes content goes here...</p>
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            </div>
            <div className={`${small ? 'col-12' : 'col-10'}`}>
                <PageHeader title={'Members'} />
                <div className='container'>
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-12 mx-auto'>{loaded ? <Deck /> : <Loader />}</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Members;
