import React, { useState,useEffect } from 'react';
import PageHeader from '../component/layout/pageheader';
import { useDispatch,useSelector } from 'react-redux';
import { Link ,useNavigate} from 'react-router-dom';
import { even_t ,event_s,checkout} from '../store/events.store';
import Carousel from 'nuka-carousel';
import Loader from '../utils/Loader';



const Events =()=>{
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const events = useSelector((state)=>state?.events?.events?.results);

    useEffect (()=>{
        dispatch(event_s())
    },[])

    const handleAttend =(id)=>{
        dispatch(checkout({"id":id}));
    }


    const maxCharactersToShow = 70;

    const truncateText = (text) => {
        return text.length > maxCharactersToShow ? `${text.slice(0, maxCharactersToShow)}...` : text;
    };

    const [expandedDetails, setExpandedDetails] = useState({});

    const handleToggleDetails = (eventId) => {
        setExpandedDetails((prev) => ({ ...prev, [eventId]: !prev[eventId] }));
    };
    
    return (
            <div>
                <PageHeader title={'Events'} />
                <section className="member-page-section">
                    <div className="container">
               
                        <div className="member-wrapper">
                            <ul className="member-info mb-4">
                                <li className="all-member">
                                    <p>All Events </p>
                                </li>
                            </ul>
                            <div className="row justify-content-center px-2">
                                {events?.length > 1 ? (events.map((event) => (
                                    <div className="col-12 mx-2 mt-3" key={event.id}>


                                        <div className="lab-item member-item style-1 style-2">
                                            <div className="lab-inner row">
                                              
                                                <div className="lab-content text-start col-6">
                                                    <h6>
                                                        <Link to={`/profile/${event.id}`}>{event.name}</Link>
                                                    </h6>
                                                    <p>
                                                        <strong>Details:</strong>{' '}
                                                        {expandedDetails[event.id]
                                                            ? event.details
                                                            : truncateText(event.details)}
                                                        {event.details.length > maxCharactersToShow && (
                                                            <a className="text-info px-2 my-4" onClick={() => handleToggleDetails(event.id)} >
                                                                <strong className='text-success'>{expandedDetails[event.id] ? 'Read Less' : 'Read More'}</strong>
                                                            </a>
                                                        )}
                                                    </p>
                                                    <p>Charges: {event.charges}</p>
                                                
                                                <div className='d-flex justify-around mx-2'>
                                                    <button className='btn btn-sm mt-3  mx-2 rounded-pill' onClick={()=>handleAttend(event.id)}>Attend</button>
                                                    <button className='btn btn-sm mt-3  rounded-pill ' onClick={()=>navigate(`/event/${event.id}`)}>View </button>
                                                </div>

                                                </div>

                                                <div className="lab-thumb event-image col-6">
                                                    {event?.images?.length >= 1 ? (
                                                    <>
                                                        {event?.images.map((image, index) => (
                                                        <img key={index} src={image.image.file} alt={`Image ${index}`}/>
                                                        ))}
                                                    </>                                                                   
                                                    ) : (                                                        
                                                        <img src="https://images.unsplash.com/photo-1618042164219-62c820f10723?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D" 
                                                        style={{
                                                            height:"4cm"
                                                        }}
                                                        alt="Place holder image"/>                                          
                                                    )}
                                                </div>
                                            </div>
                                        </div>


                                    </div>)
                                )):(<Loader/>)}


                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
}

export default Events;