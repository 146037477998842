import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import Account from '../../pages/UserAccount/Account';
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from '../../utils/local-storage';



const Header = () => {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const token = getFromLocalStorage('token');
    const user_name = useSelector((state) => state?.users?.accountDetails?.full_name);

    const menuTrigger = () => {
        document.querySelector('.menu').classList.toggle('active');
        document.querySelector('.header-bar').classList.toggle('active');
    };

    const handleClose = () => setShowOffcanvas(false);
    const handleShow = () => setShowOffcanvas(true);


    const menuTriggerTwo = () => {
        document.querySelector('.header-top').classList.toggle('open');
        // document.querySelector('.header-bar').classList.toggle('active')
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header-section').classList.add('header-fixed', 'fadeInUp');
            } else {
                document.querySelector('.header-section').classList.remove('header-fixed', 'fadeInUp');
            }
        });
    }, []);

    return (
        <header className="header-section">
            <div className="header-top">
                <div className="container">
                   
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo" style={{width:"2cm"}}>
                            <Link to="/">
                                <img src="assets/images/logo/logo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="menu-area">
                            <ul className="menu">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/events">Events</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/members">Members</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/packages">Packages</NavLink>
                                </li>
                                {!token ? (
                                    <>
                                        <li>
                                            <NavLink to="/contact">Contact</NavLink>
                                        </li>
                                        <li className="mobi-auth">
                                            <NavLink to="/login">Login</NavLink>
                                        </li>
                                        <li className="mobi-auth">
                                            <NavLink to="/signup">Sign Up</NavLink>
                                        </li>
                                    </>
                                ) : (
                                    <li className="mobi-auth"
                                    // onClick={()=>handleShow()}
                                    >
                                        <NavLink to="#">
                                            <i className="icofont-user"></i>
                                            {user_name}
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                            {!token ? (
                                <>
                                    <NavLink to="/login" className="login"><i className="icofont-user"></i> <span>LOG IN</span>{' '}</NavLink>
                                    <NavLink to="/signup" className="signup"><i className="icofont-users"></i> <span>SIGN UP</span>{' '}</NavLink>
                                </>
                            ) : (
                                <>
                                    <NavLink to="#" className="login" 
                                    onClick={()=>handleShow()}
                                    ><i className="icofont-user"></i> <span>My Account</span>{' '}</NavLink>
                                </>
                            )}
                 
                            <div className="header-bar d-lg-none" onClick={menuTrigger}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-lg-none" onClick={menuTriggerTwo}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas show={showOffcanvas} onHide={()=>handleClose()} placement='start' className="off-canvas"> 
                <Account/>
            </Offcanvas>
        </header>
    );
}

export default Header;
