import client from "./client";
import auth_client from "./auth_client";
import upload_client from "./upload_client";

export default {
    async login(credentials) {
        return client.parseResponse(await auth_client.post('/auth/login', credentials))
    },

    async registerUser(data) {        
        return client.parseResponse(await client.post('/auth/signup', data))
    },

    async upload(data) {        
        return client.parseResponse(await upload_client.post('/document/upload', data))
    },


    async forgotPassword(data){
        return client.parseResponse( await client.post('/auth/forgot-password', data))
    },

    async resetPassword(data){
        return client.parseResponse( await client.post('/auth/reset-password', data))
    },

    async getAccountDetails(){
        return client.parseResponse(await client.get('/user-account/details'))
    },

    // user dating
    async users(){
        return client.parseResponse(await client.get('/users'))
    },

    async user(id){
        console.log("The id is", id)
        return client.parseResponse(await client.get('/users/details',id))
    },

    async likeUser(id){
        return client.parseResponse(await client.post('/users/like/',id))
    }

}
