import React, { useState } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';
import Carousel from 'nuka-carousel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HeartFill,XCircleFill } from 'react-bootstrap-icons';

const images = [
  "https://images.unsplash.com/photo-1618042164219-62c820f10723?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1621272036047-bb0f76bbc1ad?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1639020715359-f03b05835829?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D",
  // "https://images.unsplash.com/photo-1634455257140-b5ab16f0770e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHVuc3BhbHNofGVufDB8fDB8fHww"
]


const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 80,
  delay: i * 100,
});

const from = (i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });

function Deck() {
  const data = useSelector((state) => state?.users?.users?.results);
  const navigate = useNavigate();
  const [showLoveIcon, setShowLoveIcon] = useState(false);
  const [showHateIcon, setShowHateIcon] = useState(false);


  const [gone] = useState(() => new Set());
  const [props, api] = useSprings(data?.length, (i) => ({
    ...to(i),
    from: from(i),
  }));

  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.2;
    const dir = xDir < 0 ? -1 : 1;

 
    if (!down && trigger) {
      gone.add(index);
      // toast.info(`You   ${dir === -1 ? 'disliked' : 'Liked'} ${data[index]?.full_name}`,{ autoClose: 500 });
      if(dir === 1){
        setShowLoveIcon(true);    
      }else if(dir === -1){
        setShowHateIcon(true);
      }

      setTimeout(() => {
        setShowHateIcon(false);
        setShowLoveIcon(false);
      }, 1000); // Adjust 
    }

    api.start((i) => {
      if (index !== i) return;

      const isGone = gone.has(index);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0;
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0);
      const scale = down ? 1.1 : 1;

      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
      };
    });

    if (!down && gone.size === data?.length) {
      setTimeout(() => {
        gone.clear();
        api.start((i) => to(i));
      }, 600);
    }
  });

  return (
    <div id="swipe-root">
      <div className="container">
        <ToastContainer />

        <div className=''>
        {showLoveIcon && (
            <div className="love-icon">
              <HeartFill className="heart" />
            </div>
          )}

        {showHateIcon && (
            <div className="love-icon">
              <XCircleFill className="heart" />
            </div>
          )}
                  
          {props.map(({ x, y }, i) => (
            <animated.div key={i} className="deck" style={{ x, y }}>
              <animated.div {...bind(i)}>
                <div className=''>
                  {data[i]?.images?.length >= 1 ? (
                      <Carousel>
                        {data[i]?.images.map((image, index) => (
                          // <img key={index} src={image.image.file} alt={`Image ${index}`} style={{ maxHeight: '200px' }}/>
                      <img src={images[0]} alt="Place holder image" style={{ maxHeight: '500px', width:"100%" }}/>

                        ))}
                      </Carousel>               
                  ) : (
                    <Carousel>
                      <img src={images[0]} alt="Place holder image" style={{ maxHeight: '500px', width:"100%" }}/>
                    </Carousel>
                  )}
                  <div className='row d-flex justify-content-center px-2'>
                    <div className='mt-4 col-6'>
                      <h4 className='text-light'>{data[i]?.full_name}</h4>
                      {/* <h5 className='text-light'>{data[i]?.phone_number}</h5>
                      <h5 className='text-light'>{data[i]?.email}</h5> */}
                    </div> 

                    <div className='col-6'>
                      <button className='mt-4 w-100 btn  rounded-pill px-0'
                      onClick={()=>navigate(`/profile/${data[i]?.id}`)}
                      ><small>View </small></button>
                    </div>

                  </div>
               
                </div>
              </animated.div>
            </animated.div>
          ))}
        </div>

      </div>
    </div>
  );
}

export default Deck;
