import React, { useEffect } from 'react';
import PageHeader from '../component/layout/pageheader';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'nuka-carousel';
import { even_t } from '../store/events.store';

const Event = () => {
    const id = useParams();
    const dispatch = useDispatch();
    const User = useSelector((state) => state?.users?.user);

    useEffect(() => {
        dispatch(even_t(id));
    }, []);

    return (
        <div className="profile-page">
            <PageHeader title={'Event Details'} />
            <section className="profile-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="profile-cover">
                                
                                {User?.images?.length >= 1 ? (
                                    <Carousel>
                                        {User?.images.map((image, index) => (
                                        <img key={index} src={image.image.file} alt={`Image ${index}`} style={{ maxHeight: '200px' }}/>
                                        ))}
                                    </Carousel>               
                                ) : (
                                    <Carousel>
                                    <img src="https://images.unsplash.com/photo-1618042164219-62c820f10723?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D" alt="Place holder image" style={{ maxHeight: '500px', width:"100%" }}/>
                                    </Carousel>
                                )}

                            </div>
                           
                        </div>
                        <div className="col-lg-8">
                            <div className="profile-information">
                                <div className="profile-name">
                                    <h2>{User?.full_name}</h2>
                                    <p>{User?.email}</p>
                                    <p>{User?.phone_number}</p>
                                    <p>Account Status: {User?.account_status}</p>
                                    <p>Last Active: 2 minutes ago</p>
                                </div>
                       
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Event;
