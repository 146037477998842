import { useNavigate, Link } from 'react-router-dom';
import React from 'react';
import { HouseDoorFill, CalendarEventFill, GiftFill, Person } from 'react-bootstrap-icons';

const BottomBar = React.memo(() => {
  const navigate = useNavigate();
  return (
    <div className="bottom-bar row pb-5">
      <Link to="/matches" className='col-3'>
        <HouseDoorFill className="icon"/>
      </Link>

      <Link to="/my-booked-events" className='col-3'>
        <CalendarEventFill className="icon"/>

      </Link>

      <Link to="/my-packages" className='col-3'>
        <GiftFill className="icon" />
      </Link>

      <Link to="/account" className='col-3'>
        <Person className="icon" />
      </Link>
    </div>
  )
})

export default BottomBar;
