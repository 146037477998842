import { Spinner } from 'react-bootstrap';

const Loader = () => {
    return (
        <div className='loader' style={{
            position:"absolute",
            left:"40vw",
            top:"40vh"
        }}>


            <Spinner
                animation="grow"
                role="status"
                style={{
                    backgroundImage: 'https://matchkenya.com/assets/images/logo/logo.png', 
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '5rem',
                    height: '5rem',
                    left: '50vw',
                    color: '#7d6e97' // Set the color to #391965
                }}
            >
                <span className="visually-hidden">Loading...</span>
            </Spinner>

        </div>
    )
}

export default Loader;