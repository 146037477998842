import React, { useEffect } from 'react';
import PageHeader from '../component/layout/pageheader';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getPackages, getPackage, checkout } from '../store/packages.store';


const Packages = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const packages = useSelector((state) => state?.packages?.packages?.results);
    const packageDetail = useSelector((state) => state?.packages?.package);

    useEffect(() => {
        dispatch(getPackages());
    }, []);

    const handleDetails = (id) => {
        dispatch(getPackage({ "id": id }));
    }

    const handleGet = (id) => {
        dispatch(checkout({ "id": id }));
        navigate("/invoices");
    }

    return (
        <div>
            <PageHeader title={'Packages'} />
            <section className="member-page-section">
                <div className="container">
                <ul className="member-wrapper member-info mb-4">
                            <li className="all-member">
                                <p>All Packages </p>
                            </li>
                        </ul>
                    <div className="row">
                        {packages?.map((pack_age) => (
                            <div className="col-md-6 col-lg-4 col-xl-3 mb-4 mt-4" key={pack_age.id}>
                                <div className="card package-card shadow" style={{border:"0"}}>
                                    {/* <img src="assets/images/Profile.jpg" className="card-img-top" alt={pack_age.name} /> */}
                                    <div className="card-body " style={{background:"#210053", color:"white"}}>
                                        <h5 className="card-title">{pack_age.name}</h5>
                                        <p className="card-text">Charges: {pack_age.amount}</p>
                                        <button className="btn mx-2 btn-details" data-bs-toggle="modal" data-bs-target="#packageModal" onClick={() => handleDetails(pack_age.id)}>Details</button>
                                        <button className="btn   btn-get" onClick={() => handleGet(pack_age.id)}>Get</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Package Modal */}
            <div className="modal fade" id="packageModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-dark" id="exampleModalLabel">{packageDetail?.name}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className='text-dark'>Charges: {packageDetail?.amount}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Packages;
