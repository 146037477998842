import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { PersonFill, PeopleFill, CalendarEventFill, HouseFill } from 'react-bootstrap-icons';
import { RiFileListLine } from 'react-icons/ri';
import { BiLogOut } from 'react-icons/bi';
import { useSelector } from 'react-redux';


const Account =()=> {
  const user = useSelector((state) => state?.users?.accountDetails);


  return (
    <div className="account">
      <div className="account-card">
        <div className="account-img">
          <img src="https://itstrevor.vercel.app/img/1.jpg" alt="user image" />
        </div>

        <div  className="account-details text-center">
            <h4 className="user-name">{user?.full_name}</h4>
            <p className="user-email">{user?.email}</p>
            <p className="user-phone">{user?.phone_number}</p>
        </div>
      </div>
      <div className="account-details text-start p-5">
        
        <ul className="account-links">
          <li className="mb-4">
            <NavLink to="/">
              <HouseFill /><span className="mx-3">Home</span>
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/my-booked-events">
              <CalendarEventFill /><span className="mx-3">Booked Events</span>
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/matches">
              <PeopleFill /><span className="mx-3">My Matches</span>
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/my-packages">
              <PersonFill /><span className="mx-3">My Packages</span>
            </NavLink>
          </li>

          <li className="mb-5">
            <NavLink to="/invoices">
              <RiFileListLine /><span className="mx-3">My Invoices</span>
            </NavLink>
          </li>

          <li className="mt-5">
            <NavLink to="/logout">
              <BiLogOut /><span className="mx-3"> Logout</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Account;