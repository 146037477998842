import { configureStore,combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import usersReducer from "./users.store";
import eventsReducer from "./events.store";
import invoiceReducer from "./invoice.store";
import packageReducer from "./packages.store";
import paymentsReducer from "./payments.store";


// combining reducers
const rootReducer = combineReducers({
  users:usersReducer,
  events: eventsReducer,
  packages: packageReducer,
  invoices: invoiceReducer,
  payments:paymentsReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
