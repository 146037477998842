/* eslint-disable class-methods-use-this */
import axios from 'axios';
import _ from 'lodash';
import {getFromLocalStorage} from "../utils/local-storage";


class Client {
    //The constructor, attributes definitions
    constructor(){
        //Setting the header variables
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Accept'] = '*/*';
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';


        const meta = document.head.querySelector('meta[name="csrf-token"]');
        if (meta) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = meta.content;
        }

        const token = getFromLocalStorage('token') //Getting the user token
        if (token & ! window.location.pathname.includes("/login")) {
            // Token exists
            // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            console.log("The tokem is ", token)
        } else{
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        }

       
  

        
        //creating the base url
        this.http = axios.create({
            baseURL : "https://portal.matchkenya.com/api/v1"    //local
        });


        this.http.interceptors.response.use(response => response, async (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                if (!window.location.href.includes("/login") && !window.location.href.includes("/register")) {
                    window.location.href = '/login';
                }

                window.location.href = '/login';

            }
            return Promise.reject(error);
        });
    }

      
    //The functions
    get(path, params, config) {
        return this.http.get(path, {params, ...config});
    }

    post(path, data, config) {
        return this.http.post(path, data, config);
    }

    upload(path, data, file, config) {
        const form = new FormData();
        _.each(data, (value, key) => {
            form.append(key, value);
        });
        return this.post(path, form, {
            ...config,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    put(path, data, config) {
        return this.http.put(path, data, config);
    }

    delete(path, params, config) {
        return this.http.delete(path, {params, ...config});
    }

    parseResponse({data}) {
        return data;
    }
}

export default new Client();
