import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/users.store';
import Header from '../../component/layout/header';
import PageHeader from '../../component/layout/pageheader';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getFromLocalStorage } from '../../utils/local-storage';
import Loader from '../../utils/Loader';

// import FooterSection from '../../component/layout/footer';

const title = "Login";

const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.users.loading);
    const token = getFromLocalStorage('token') //Getting the user token

    const handleFormSubmit = (values, { setSubmitting }) => {
        dispatch(loginUser(values)).then((result) => {
            if (result?.payload?.token) {
                // navigate("/dashboard")      
                window.location.pathname = "/members";
            }
        })
        setSubmitting(false)
    }

    return (
        <div>
            <Header />
            <PageHeader title={'Log In'} />
            <div className="login-section padding-tb ">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <Formik
                            initialValues={{
                                email: "",
                                password: ""
                            }}
                            onSubmit={handleFormSubmit}
                        >
                            <Form className="account-form">
                                <div className="form-group">
                                    <Field
                                        type="email"
                                        name="email"
                                        id="item01"                                        
                                        placeholder="Email *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="password"
                                        name="password"
                                        id="item02"
                                        placeholder="Password *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                        <div className="checkgroup">
                                            <input type="checkbox" name="remember" id="remember" />
                                            <label htmlFor="remember">Remember Me</label>
                                        </div>
                                        <a href="#">Forget Password?</a>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='mx-auto text-center mt-2'>{loading ? <Loader /> : ""} </div>

                                    <button disabled={loading} className="d-block lab-btn" type="submit"><span>Submit Now</span></button>
                                </div>
                            </Form>
                        </Formik>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Don’t Have any Account? <Link to="/signup"> Sign Up</Link></span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterSection /> */}
            <ToastContainer/>

        </div>
    );
};

export default LoginPage;
