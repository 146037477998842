import users from './users.api'
import events from './events'
import invoice from './invoice'
import payments from './payments'
import packages from './packages'

export default {
    ...users,
    ...events,
    ...invoice,
    ...payments,
    ...packages
}

