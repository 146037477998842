import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { upload } from '../../store/users.store';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/layout/header';
import PageHeader from '../../component/layout/pageheader';
import { useSelector } from "react-redux";


const Upload = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const loading = useSelector((state) => state.users.loading);


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            console.error("No file selected");
            return;
        }
        const formData = new FormData();
        formData.append("file", file); 

        dispatch(upload(formData)).then((result) => {
            if (result?.payload?.successful === 1) {
                console.log("The result is", result);
                navigate("/signup")
            }
        });
    };



    return (
        <div>
            {/* <Header /> */}
            <PageHeader title={'Sign Up'} />
            <div className="login-section ">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Transform pixels into memories: upload your image </h3>
                        <form onSubmit={handleSubmit} className="upload-form">
                            <div className="form-group">
                            <label htmlFor="file" className="file-label">
                                <span className="file-icon h1 mx-4">&#x1F4F7;</span>
                                {file && (
                                <div className="shadow row w-100 ">
                                    <div className='p-3'>
                                        <img src={URL.createObjectURL(file)} alt="Selected" className="w-100selected-preview" />
                                    </div>
                                </div>
                                )}

                                <input type="file" id="file"
                                    name="file"
                                    multiple
                                    className="mt-4"
                                    onChange={(e)=>setFile(e.target.files[0])}
                                />
                            </label>
                            </div>


                            <button type="submit" 
                            disabled={loading}
                            className="d-block btn-outline-success btn w-100  mt-4 px-1 py-2">
                                <strong className=''>{file ? "Next" : "Upload"}</strong>
                                <span className={`${!file ? 'd-none':''} file-icon h2`}>&#x2192;</span>

                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Upload;
